import { Form, Input, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { images } from "assets/iconString";
import { UpdateType } from "utils/enum";
import CustomButton from "components/CustomButton";
import styles from "./styles.module.scss";
import LabelInput from "components/LabelInput";
import { commonValidate } from "utils/ruleForms";
import classNames from "classnames";
import { IBussinessGroup } from "utils/interface";
import { useEffect } from "react";

interface IProps {
  open: boolean;
  shouldSubmitBtnBeDisabled: boolean;
  data?: IBussinessGroup | null;
  type: UpdateType;
  onCancel: () => void;
  onSubmit: (data: any) => void;
}
export default function GroupModal({
  open,
  type,
  data,
  shouldSubmitBtnBeDisabled,
  onCancel,
  onSubmit,
}: IProps) {
  const [t] = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    if (open) form.resetFields();
    form.setFieldsValue({
      groupName: data?.groupName,
      id: data?.id,
    });
  }, [open, data, form]);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      closable={false}
      footer={null}
      className="modalCustom"
      title={
        <>
          <div>
            {type === UpdateType.CREATE
              ? t("modal.addGroupnameModalTitle")
              : t("modal.updateGroupnameModalTitle")}
          </div>
          <div onClick={onCancel}>
            <img src={images.closeModalIcon} alt="close icon" />
          </div>
        </>
      }
    >
      <Form form={form} onFinish={onSubmit} className={styles.formContainer}>
        <div className={classNames(styles.row, "f-1")}>
          <div className="f-1">
            <LabelInput
              title={t("addGroupnameModalText.groupname")}
              isRequired
            />
            <Form.Item
              name="groupName"
              rules={
                type === UpdateType.CREATE
                  ? [commonValidate.required, commonValidate.whiteSpace]
                  : [commonValidate.whiteSpace]
              }
              // validateFirst
            >
              <Input
                className="inputCustom"
                placeholder={t("placeholder.groupname")}
                maxLength={50}
              />
            </Form.Item>
          </div>
          {data?.id && <Form.Item name="id" />}
        </div>
      </Form>
      <CustomButton
        title={t("button.save")}
        onClick={() => form.submit()}
        className={styles.btnSubmit}
        disabled={shouldSubmitBtnBeDisabled}
      />
    </Modal>
  );
}
