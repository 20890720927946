import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { dataRanking } from "constants/defaultValue";
import dayjs from "dayjs";
import { usePermissions, useRole } from "hooks/usePermissions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { listColorStatus } from "utils/const";
import { CandidateRankingConfig } from "utils/enum";
import {
  dateUtils,
  getIndexTable,
  returnStatusApplication,
} from "utils/helper";
import { IApplicationRow, IFilter } from "utils/interface";
import styles from "./styles.module.scss";

interface IProps {
  data: IApplicationRow[];
  filter: IFilter;
  loading: boolean;
  listStoreActive?: any[];
}

export default function HistoryTable({
  data,
  filter,
  loading,
  listStoreActive,
}: IProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const permissions = usePermissions();
  const role = useRole();

  const renderCrownRanking = (
    ranking: CandidateRankingConfig,
    className?: string,
    width?: number,
    height?: number
  ) => {
    if (!ranking || ranking === CandidateRankingConfig.NONE) return null;
    return (
      <img
        className={className}
        src={dataRanking[ranking]?.icon}
        width={width || 20}
        height={height || 13.5}
        alt="check"
      />
    );
  };

  const columns: ColumnsType<IApplicationRow> = [
    {
      title: t("table.index"),
      dataIndex: "index",
      key: "index",
      render: (value, record, index) => (
        <div>{getIndexTable(filter.page || 1, filter.limit || 10, index)}</div>
      ),
    },
    {
      title: t("table.date"),
      dataIndex: "date",
      key: "date",
      render: (value, record, index) => (
        <div>{dateUtils.getDateCreate(record?.createdAt)}</div>
      ),
    },
    {
      title: t("table.nickname"),
      dataIndex: "nickname",
      key: "nickname",
      render: (value, record, index) => (
        <div
          onClick={() =>
            navigate(`/users/detail/${record?.candidateProfile?.accountId}`)
          }
          className={styles.wrapLink}
        >
          {record?.candidateProfile?.nickname}
        </div>
      ),
    },
    {
      title: t("table.status"),
      dataIndex: "status",
      width: 160,
      key: "status",
      render: (value, record, index) => (
        <div
          className={styles.wrapStatus}
          style={{
            backgroundColor: listColorStatus[record?.status],
          }}
        >
          {t(returnStatusApplication(record?.status) || "")}
        </div>
      ),
    },
    {
      title: t("table.dateWork"),
      dataIndex: "dateWork",
      key: "dateWork",
      render: (value, record, index) => (
        <span className="">{`${dateUtils.getDateCreate(
          record?.recruitmentPost?.startAt
        )} -> ${dayjs(record?.recruitmentPost?.startAt)
          .add(record?.recruitmentPost?.businessHours, "hour")
          .format("YYYY/MM/DD HH:mm")}`}</span>
      ),
    },
    {
      title: t("table.detailWork"),
      dataIndex: "detailWork",
      width: "35%",
      key: "detailWork",
      render: (value, record, index) => (
        <div
          onClick={() => navigate(`/posts/detail/${record?.recruitmentPostId}`)}
          className={styles.wrapLink}
        >
          {record?.recruitmentPost?.title}
        </div>
      ),
    },
    {
      title: t("table.agencyName"),
      dataIndex: "agencyName",
      key: "agencyName",
      render: (value, record, index) => (
        <div
          onClick={() =>
            navigate(
              `/store-account/store-list/detail/${record?.recruitmentPost?.business?.id}`
            )
          }
          className={styles.wrapLink}
        >
          {record?.recruitmentPost?.business?.staffInCharge?.name}
        </div>
      ),
    },
    {
      title: t("table.nameStore"),
      dataIndex: "nameStore",
      key: "nameStore",
      render: (value, record, index) => (
        <div
          onClick={() =>
            navigate(
              `/store-account/store-list/detail/${record?.recruitmentPost?.business?.id}`
            )
          }
          className={styles.wrapLink}
        >
          {record?.recruitmentPost?.business?.name}
        </div>
      ),
    },
  ];
  return (
    <>
      <Table
        rowKey={(obj) => obj?.id}
        pagination={false}
        columns={columns}
        dataSource={data}
        className="tableCustom"
        loading={loading}
      />
    </>
  );
}
