import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Badge, Menu, Row } from "antd";

import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import Icon from "@ant-design/icons";

import Sider from "antd/lib/layout/Sider";
import {
  ArrowLeft,
  BellIcon,
  ChartIcon,
  CrownIcon,
  Element4Icon,
  GroupIcon,
  HeadphoneIcon,
  IconDataAnalysis,
  IconFAQDefault,
  Logo,
  PostIcon,
  SettingIcon,
  ShopIcon,
  UserIcon,
  WarningIcon,
  CampaignIcon,
  FlagIcon,
  IconPhone,
  IconChain,
} from "assets/icon";
import {
  handleIndexRoutes,
  hasCreatePermission,
  hasViewPermission,
} from "utils/helper";
import useToggleSideNav from "hooks/useToggleSideNav";
import { SIDE_NAV_WIDTH, SIDE_NAV_WIDTH_COLLAPSED } from "utils/const";
import { ELocalStorageKey, FeatureManagement, RoleType } from "utils/enum";
import { usePermissions, useRole } from "hooks/usePermissions";
import { useQuery } from "react-query";
import { countForSideBar } from "utils/queryKey";
import { getCountForSideBar } from "api/common";
import classNames from "classnames";
import { IconInvoice } from "assets/images";

const { SubMenu } = Menu;

export default function SideNav() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { collapsed, toggleSideNav } = useToggleSideNav();
  const [selectedKey, setSelectedKey] = useState("1");
  const permissions = usePermissions();
  const role = useRole();
  const isAdmin = role?.type === RoleType.ADMIN || !!role?.isSuperAdmin;
  const isSale = role?.type && role?.type === RoleType.SALE;
  const isStaff = role?.name && role?.name === RoleType.STAFF;
  const isAccounting = role?.name && role?.name === RoleType.ACCOUNTING;

  const { data: countSidebar } = useQuery({
    queryKey: countForSideBar,
    queryFn: () => getCountForSideBar(),
  });

  const routesBeforeProcessing = [
    {
      text: t("nav.dashboard"),
      url: "/dashboard",
      icon: <Icon component={() => <ChartIcon />} />,
      visible: isAdmin || isStaff,
    },
    {
      text: t("nav.staffs"),
      icon: <Icon component={() => <GroupIcon />} />,
      visible:
        hasViewPermission(
          permissions[FeatureManagement.EMPLOYER_LIST_MANAGEMENT],
          role
        ) ||
        hasViewPermission(
          permissions[FeatureManagement.EMPLOYER_ROLE_MANAGEMENT],
          role
        ),
      children: [
        {
          text: t("nav.staffList"),
          url: "/staff/staff-list",
          visible: hasViewPermission(
            permissions[FeatureManagement.EMPLOYER_LIST_MANAGEMENT],
            role
          ),
        },
        {
          text: t("nav.roleManage"),
          url: "/staff/role-manage",
          visible: hasViewPermission(
            permissions[FeatureManagement.EMPLOYER_ROLE_MANAGEMENT],
            role
          ),
        },
      ],
    },

    {
      text: t("nav.users"),
      url: "/users",
      icon: <Icon component={() => <UserIcon />} />,
      visible: hasViewPermission(
        permissions[FeatureManagement.USER_ACCOUNT_MANAGEMENT],
        role
      ),
    },
    {
      text: t("nav.applicationHistory"),
      url: "/application-history",
      icon: (
        <Icon
          component={() => (
            <img src={IconPhone} width={23} height={23} alt="smartphone" />
          )}
        />
      ),
      visible: isAdmin || isStaff,
    },
    {
      text: (
        <Row align="middle" justify="space-between">
          <p>{t("nav.stores")}</p>
          {hasViewPermission(
            permissions[FeatureManagement.APPROVAL_STORE_ACCOUNT],
            role
          ) && (
            <Badge
              className={styles.badgeRound}
              count={countSidebar?.data?.businessTotal}
              size="small"
            />
          )}
        </Row>
      ),
      icon: <Icon component={() => <ShopIcon />} />,
      visible:
        hasViewPermission(
          permissions[FeatureManagement.APPROVAL_STORE_ACCOUNT],
          role
        ) ||
        hasViewPermission(
          permissions[FeatureManagement.STORE_ACCOUNT_MANAGEMENT],
          role
        ),
      children: [
        {
          text: (
            <Row align="middle" justify="space-between">
              <p>{t("nav.saleInactiveStores")}</p>
              <Badge
                className={classNames([styles.badge], [styles.badgeRound])}
                count={countSidebar?.data?.businessTotal}
                size="small"
              />
            </Row>
          ),
          url: "/store-account/account-browsing",
          visible: hasViewPermission(
            permissions[FeatureManagement.APPROVAL_STORE_ACCOUNT],
            role
          ),
        },
        {
          text: isSale ? t("nav.saleActiveStores") : t("nav.storeList"),
          url: "/store-account/store-list",
          visible: hasViewPermission(
            permissions[FeatureManagement.STORE_ACCOUNT_MANAGEMENT],
            role
          ),
        },
      ],
    },
    {
      text: t("nav.posts"),
      url: "/posts",
      icon: <Icon component={() => <PostIcon />} />,
      visible: hasViewPermission(
        permissions[FeatureManagement.POSTS_MANAGEMENT],
        role
      ),
    },
    {
      text: t("nav.notiManage"),
      icon: <Icon component={() => <BellIcon />} />,
      visible:
        hasCreatePermission(
          permissions[FeatureManagement.NOTIFICATION],
          role
        ) ||
        hasViewPermission(
          permissions[FeatureManagement.EDIT_NOTIFICATION],
          role
        ),
      children: [
        {
          text: t("nav.createdNoti"),
          url: "/noti/created-noti",
          visible: hasCreatePermission(
            permissions[FeatureManagement.NOTIFICATION],
            role
          ),
        },
        {
          text: t("nav.notiContent"),
          url: "/noti/noti-content",
          visible: hasViewPermission(
            permissions[FeatureManagement.EDIT_NOTIFICATION],
            role
          ),
        },
      ],
    },
    {
      text: (
        <Row align="middle" justify="space-between">
          <p>{t("nav.plans")}</p>
          {hasViewPermission(
            permissions[FeatureManagement.REQUEST_PLAN],
            role
          ) && (
            <Badge
              className={classNames([styles.badgeRound])}
              count={countSidebar?.data?.planRequestTotal}
              size="small"
            />
          )}
        </Row>
      ),
      icon: <Icon component={() => <CrownIcon />} />,
      visible:
        hasViewPermission(permissions[FeatureManagement.REQUEST_PLAN], role) ||
        hasViewPermission(permissions[FeatureManagement.LIST_PLAN], role),
      children: [
        {
          text: (
            <Row align="middle" justify="space-between">
              <p>{t("nav.requestPlan")}</p>
              <Badge
                className={classNames([styles.badge], [styles.badgeRound])}
                count={countSidebar?.data?.planRequestTotal}
                size="small"
              />
            </Row>
          ),
          url: "/plans/request-plan",
          visible: hasViewPermission(
            permissions[FeatureManagement.REQUEST_PLAN],
            role
          ),
        },
        {
          text: t("nav.planList"),
          url: "/plans/plan-list",
          visible: hasViewPermission(
            permissions[FeatureManagement.LIST_PLAN],
            role
          ),
        },
      ],
    },
    {
      text: (
        <Row align="middle" justify="space-between">
          <p>{t("nav.bugReportManagement")}</p>
          <Badge
            className={classNames([styles.badge], [styles.badgeRound])}
            count={countSidebar?.data?.bugTotal}
            size="small"
          />
        </Row>
      ),
      url: "/bugs-report",
      icon: <Icon component={() => <WarningIcon />} />,
      visible: hasViewPermission(
        permissions[FeatureManagement.BUG_MANAGEMENT],
        role
      ),
    },

    ///invoice
    {
      text: (
        <Row align="middle" justify="space-between">
          <p>{t("nav.invoice")}</p>
          {/* {hasViewPermission(
            permissions[FeatureManagement.APPROVAL_STORE_ACCOUNT],
            role
          ) && (
            <Badge
              className={styles.badgeRound}
              count={countSidebar?.data?.businessTotal}
              size="small"
            />
          )} */}
        </Row>
      ),
      icon: (
        <Icon
          component={() => (
            <img src={IconInvoice} width={23} height={23} alt="iconInvoice" />
          )}
        />
      ),
      visible: isAdmin || isStaff || isAccounting || isSale,
      children: [
        {
          text: (
            <Row align="middle" justify="space-between">
              <p>{t("nav.invoiceList")}</p>
            </Row>
          ),
          url: "/invoice-list",
          visible: isAdmin || isStaff || isAccounting || isSale,
        },
        {
          text: t("nav.invoiceAgency"),
          url: "/invoice-agency-list",
          visible: isAdmin || isStaff || isAccounting,
        },
      ],
    },
    {
      text: (
        <Row align="middle" justify="space-between">
          <p>{t("nav.groupnameManager")}</p>
        </Row>
      ),
      url: "/groups/groupname-manager",
      icon: <Icon component={() => <IconChain />} />,
      visible: isAdmin || isStaff,
    },
    {
      text: (
        <Row align="middle" justify="space-between">
          <p>{t("nav.inquiryManagement")}</p>
          <Badge
            className={classNames([styles.badge], [styles.badgeRound])}
            count={countSidebar?.data?.contactTotal}
            size="small"
          />
        </Row>
      ),
      url: "/contacts",
      icon: <Icon component={() => <HeadphoneIcon />} />,
      visible: hasViewPermission(
        permissions[FeatureManagement.CONTRACT_MANAGEMENT],
        role
      ),
    },
    {
      text: (
        <Row align="middle" justify="space-between">
          <p>{t("nav.report")}</p>
          {/* <Badge
            className={classNames([styles.badge], [styles.badgeRound])}
            count={countSidebar?.data?.contactTotal}
            size="small"
          /> */}
        </Row>
      ),
      url: "/report",
      icon: <Icon component={() => <FlagIcon />} />,
      visible: isAdmin || isStaff,
    },
    {
      text: (
        <Row align="middle" justify="space-between">
          <p>{t("nav.campaign")}</p>
        </Row>
      ),
      icon: <Icon component={() => <CampaignIcon />} />,
      visible: isSale !== undefined ? !isSale : false,
      children: [
        {
          text: t("nav.campaignList"),
          url: "/campaigns/campaign-list",
          visible: true,
        },
        {
          text: t("nav.responseCampaign"),
          url: "/campaigns/responses-campaign",
          visible: true,
        },
      ],
    },
    {
      text: t("nav.faq"),
      url: "/faq",
      icon: <Icon component={() => <IconFAQDefault />} />,
      visible: isAdmin || isStaff,
    },
    {
      text: t("nav.settings"),
      icon: <Icon component={() => <SettingIcon />} />,
      visible: hasViewPermission(permissions[FeatureManagement.SETTING], role),
      children: [
        {
          text: t("nav.checkbox"),
          url: "/plans/checkbox-management",
          visible: true,
        },
        {
          text: t("nav.form"),
          url: "/plans/form-management",
          visible: true,
        },
        {
          text: t("nav.tooltips"),
          url: "/plans/tooltips-management",
          visible: true,
        },
        {
          text: t("nav.others"),
          url: "/plans/others-management",
          visible: true,
        },
      ],
    },
    {
      text: t("nav.masterData"),
      icon: <Icon component={() => <Element4Icon />} />,
      visible: hasViewPermission(
        permissions[FeatureManagement.MASTER_DATA],
        role
      ),
      children: [
        {
          text: t("nav.cup"),
          url: "/master-data/cup-size",
          visible: true,
        },
        {
          text: t("nav.experience"),
          url: "/master-data/experience",
          visible: true,
        },
        {
          text: t("nav.pickupRequest"),
          url: "/master-data/pickup-request",
          visible: true,
        },
        {
          text: t("nav.panel"),
          url: "/master-data/panel",
          visible: true,
        },
        {
          text: t("nav.areaList"),
          url: "/master-data/area-level-1",
          visible: true,
        },
        {
          text: t("nav.industry"),
          url: "/master-data/industry-level-1",
          visible: true,
        },
        {
          text: t("nav.review"),
          url: "/master-data/review-tag",
          visible: true,
        },
        {
          text: t("nav.applicationDeadline"),
          url: "/master-data/application-deadline",
          visible: true,
        },
        {
          text: t("nav.workingHours"),
          url: "/master-data/working-hours",
          visible: true,
        },
        {
          text: t("nav.treatment"),
          url: "/master-data/treatment",
          visible: true,
        },
        {
          text: t("nav.questionnaire"),
          url: "/master-data/questionnaire-manager",
          visible: true,
        },
        {
          text: t("nav.addPost"),
          url: "/master-data/add-posts",
          visible: true,
        },
        {
          text: t("nav.popupManager"),
          url: "/master-data/popup-manager",
          visible: true,
        },
      ],
    },
    {
      text: t("nav.dataAnalysis"),
      icon: <Icon component={() => <IconDataAnalysis />} />,
      visible: isAdmin || isSale || isStaff || isAccounting,
      children: [
        {
          text: t("nav.storeDistribution"),
          url: "/data-analysis/store-distribution",
          visible: isAdmin || isStaff || isAccounting,
        },
        {
          text: t("nav.ageAnalysis"),
          url: "/data-analysis/age",
          visible: isAdmin || isStaff || isAccounting,
        },
        {
          text: t("nav.treatmentSearch"),
          url: "/data-analysis/treatment-search",
          visible: isAdmin || isSale || isStaff || isAccounting,
        },
        {
          text: t("nav.favoriteStores"),
          url: "/data-analysis/favorite-stores",
          visible: isAdmin || isStaff || isAccounting,
        },
        {
          text: t("nav.industryAnalysis"),
          url: "/data-analysis/industry",
          visible: isAdmin || isStaff,
        },
        {
          text: t("nav.industryStatistic"),
          url: "/data-analysis/industry-statistic",
          visible: isAdmin || isStaff || isAccounting,
        },
      ],
    },
  ];

  const routes = handleIndexRoutes(routesBeforeProcessing);

  useEffect(() => {
    routes.forEach((route) => {
      if (location.pathname.startsWith(route.url || "###")) {
        setSelectedKey(route.key);
      }
      if (route.children) {
        route.children.forEach((childRoute) => {
          if (location.pathname.startsWith(childRoute.url || "###")) {
            setSelectedKey(childRoute.key);
          }
        });
      }
    });
  }, [location.pathname]);

  useEffect(() => {
    const firstVisibleRoute: any = routes.find((route) => route.visible);
    if (
      location.pathname === "/" &&
      firstVisibleRoute?.visible &&
      permissions &&
      role
    ) {
      if (firstVisibleRoute?.children) {
        const firstChildrenVisible = firstVisibleRoute?.children?.find(
          (route: any) => route.visible
        );
        navigate(firstChildrenVisible?.url);
      } else {
        navigate(firstVisibleRoute?.url);
      }
    }
  }, [location.pathname, permissions, role]);

  return (
    <div
      className={styles.side_nav}
      style={{
        width: collapsed ? SIDE_NAV_WIDTH_COLLAPSED : SIDE_NAV_WIDTH,
        transition: "width 0.3s",
      }}
    >
      <div className={styles.logo_container}>
        <Link to="/">
          <Logo
            className={styles.logo}
            style={
              collapsed
                ? { width: SIDE_NAV_WIDTH_COLLAPSED, height: "auto" }
                : {}
            }
          />
        </Link>
      </div>
      <Sider
        width={SIDE_NAV_WIDTH}
        className={`${styles.side_nav__sider} scroll`}
      >
        <Menu
          selectedKeys={[selectedKey]}
          defaultOpenKeys={[]}
          mode="inline"
          inlineCollapsed={collapsed}
        >
          <Menu.Item
            icon={<ArrowLeft style={{ height: 24, width: 24 }} />}
            onClick={toggleSideNav}
          >
            <div>{t("nav.menu")}</div>
          </Menu.Item>
          {routes.map((route) => {
            if (route.visible === false) {
              return null;
            }
            if (route.children) {
              return (
                <SubMenu key={route.key} icon={route.icon} title={route.text}>
                  {route.children?.map((childRoute) => {
                    if (childRoute.visible === false) {
                      return null;
                    }
                    return (
                      <Menu.Item key={childRoute.key}>
                        <Link to={childRoute.url}>{childRoute.text}</Link>
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              );
            }
            return (
              <Menu.Item key={route.key} icon={route.icon}>
                <Link to={route.url || ""}>{route.text}</Link>
              </Menu.Item>
            );
          })}
        </Menu>
      </Sider>
    </div>
  );
}
