const configs = {
  APP_ENV: process.env.REACT_APP_ENV,
  API_DOMAIN: process.env.REACT_APP_API_DOMAIN,
  isDebugOrDev:
    process.env.NODE_ENV === "development" ||
    process.env.REACT_APP_ENV === "dev",
  isDebug: process.env.NODE_ENV === "development",

  AWS_DOMAIN: process.env.REACT_APP_AWS_DOMAIN,
  TINYMCE_KEY: process.env.REACT_APP_TINYMCE_KEY,
};

export default configs;
