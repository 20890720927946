import Icon from "@ant-design/icons";
import {
  createBusinessGroup,
  getListBusinessGroup,
  updateBusinessGroup,
} from "api/businessGroup";
import { AddIcon } from "assets/icon";
import CustomButton from "components/CustomButton";
import CustomNotification from "components/CustomNotification";
import PaginationCustom from "components/PaginationCustom";
import useFilter from "hooks/useFilter";
import useGetFilterQuery from "hooks/useGetFilterQuery";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKey, UpdateType } from "utils/enum";
import { convertFilterToValidForm, handleErrorMessage } from "utils/helper";
import { IBussinessGroup } from "utils/interface";
import GroupFilter from "./components/GroupFilter";
import GroupModal from "./components/GroupModal";
import GroupTable from "./components/GroupTable";
import styles from "./styles.module.scss";

export default function GroupNameManager() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [modalController, setModalController] = useState<{
    open: boolean;
    type: UpdateType;
    data: null | IBussinessGroup;
  }>({
    open: false,
    type: UpdateType.CREATE,
    data: null,
  });

  const { filter, handleSearch, handlePageChange, handleFilterChange } =
    useFilter(
      useGetFilterQuery({
        defaultFilter: {
          page: 1,
          limit: 10,
        },
        convertFilterToValidForm,
      }),
      true
    );

  const handleMutateSuccess = () => {
    setModalController((prev) => ({
      open: false,
      type: UpdateType.CREATE,
      data: { groupName: "", id: -1 },
    }));
    CustomNotification({
      type: "success",
      message: t("notification.success"),
    });
    queryClient.invalidateQueries([QueryKey.LIST_BUSINESS_GROUPS, filter]);
  };

  const createBusinessGroupMutation = useMutation({
    mutationFn: createBusinessGroup,
    onSuccess: handleMutateSuccess,
    onError(error, variables, context) {
      handleErrorMessage(error);
    },
  });
  const updateBusinessGroupMutation = useMutation({
    mutationFn: updateBusinessGroup,
    onSuccess: handleMutateSuccess,
    onError(error, variables, context) {
      handleErrorMessage(error);
    },
  });

  const { data: businessGroups } = useQuery({
    queryKey: [QueryKey.LIST_BUSINESS_GROUPS, filter],
    queryFn: () => getListBusinessGroup({ ...filter }),
  });

  const handleSubmit = (data: any) => {
    if (modalController.type === UpdateType.CREATE) {
      createBusinessGroupMutation.mutate(data);
    } else {
      updateBusinessGroupMutation.mutate(data);
    }
  };

  return (
    <Fragment>
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <div className={styles.postHeader}>
            <div className={styles.title}>{t("title.groupManager")}</div>
            <CustomButton
              title={t("button.addGroup")}
              icon={<Icon component={() => <AddIcon />} />}
              className={styles.addGroupBtn}
              onClick={() => {
                setModalController((prev) => ({
                  ...prev,
                  open: true,
                  type: UpdateType.CREATE,
                  data: null,
                }));
              }}
            />
          </div>
          <GroupFilter onSearch={handleFilterChange} filter={filter} />
        </div>
        <div className={styles.storeTable}>
          <GroupTable
            data={businessGroups?.data?.items || []}
            filter={filter}
            onEdit={(data: IBussinessGroup) => {
              setModalController((prev) => ({
                ...prev,
                open: true,
                data: data,
                type: UpdateType.UPDATE,
              }));
            }}
          />
          <PaginationCustom
            totalItems={businessGroups?.data?.totalItems || 0}
            pageIndex={filter.page || 1}
            pageSize={filter.limit || 10}
            onPageChange={handlePageChange}
            className="mt-20"
          />
        </div>
      </div>
      <GroupModal
        {...modalController}
        onCancel={() =>
          setModalController((prev) => ({ ...prev, open: false }))
        }
        shouldSubmitBtnBeDisabled={
          createBusinessGroupMutation.isLoading ||
          updateBusinessGroupMutation.isLoading
        }
        onSubmit={handleSubmit}
      />
    </Fragment>
  );
}
