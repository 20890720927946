import { Checkbox, Table } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import type { ColumnsType } from "antd/es/table";
import { QueryKey } from "utils/enum";
import { IFilter, IRecordInvoice } from "utils/interface";
import {
  convertPoint,
  dateUtils,
  getIndexTable,
  handleErrorMessage,
} from "utils/helper";
import { usePermissions, useRole } from "hooks/usePermissions";
import { dataRanking } from "constants/defaultValue";
import dayjs from "dayjs";
import styles from "./styles.module.scss";
import { invoiceStatusList } from "utils/const";
import { Detail } from "components/ActionTable/ActionTable";
import { useMutation, useQueryClient } from "react-query";
import { updateStatusInvoice } from "api/invoice";

interface IProps {
  data: any[];
  filter: IFilter;
  loading: boolean;
}

export default function TableDetailInvoice({ data, filter, loading }: IProps) {
  const { t } = useTranslation();

  const columns: ColumnsType<any> = [
    {
      title: <div>{t("table.index")}</div>,
      dataIndex: "index",
      key: "index",
      render: (value, record, index) => (
        <div>{getIndexTable(filter.page || 1, filter.limit || 10, index)}</div>
      ),
    },
    {
      title: t("table.storeName"),
      dataIndex: "storeName",
      width: "40%",

      key: "storeName",
      render: (value, record, index) => <p>{record?.name}</p>,
    },
    {
      title: (
        <div>
          <p className={styles.priceCol}>{t("table.price")}</p>
        </div>
      ),
      dataIndex: "price",
      width: "20%",
      key: "price",
      render: (value, record, index) => (
        <div className={styles.priceCol}>
          {!record?.invoice ? "_" : convertPoint(record?.invoice?.totalAmount)}
        </div>
      ),
    },

    {
      title: <p className={styles.priceCol}>{t("table.fee")}</p>,
      dataIndex: "fee",
      key: "fee",
      width: "20%",

      render: (value, record, index) => (
        <div className={styles.priceCol}>
          {!record?.invoice
            ? "_"
            : convertPoint(record?.invoice?.totalAmountFee)}
        </div>
      ),
    },
  ];
  return (
    <>
      <Table
        rowKey={(obj) => obj?.id}
        pagination={false}
        columns={columns}
        dataSource={data}
        className="tableCustom"
        loading={loading}
        locale={{ emptyText: t("invoice.empty") }}
      />
    </>
  );
}
