import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";

// import TableStore from "./components/TableStore";
import {
  getListInvoiceAgency,
  getPdfInvoiceAgency,
  getPdfZipInvoiceAgency,
} from "api/invoice";
import PaginationCustom from "components/PaginationCustom";
import useFilter from "hooks/useFilter";
import useGetFilterQuery from "hooks/useGetFilterQuery";
import useQueryParams from "hooks/useQueryParams";
import Loading from "pages/Loading/Loading";
import queryString from "query-string";
import { useState } from "react";
import { DEFAULT_LIMIT } from "utils/const";
import { QueryKey } from "utils/enum";
import { convertOriginFilter, handleErrorMessage } from "utils/helper";
import InvoiceAgencyFilter from "./components/InvoiceFilter";
import InvoiceAgencyTable from "./components/InvoiceTable";
import styles from "./styles.module.scss";

export default function InvoiceAgency() {
  const [t] = useTranslation();
  const queryParams = queryString.parse(window.location.search) as any;
  const [currentDate, setCurrentDate] = useState(
    dayjs(queryParams?.startAt).isValid()
      ? dayjs(queryParams?.startAt)
      : dayjs()
  );
  const [listCheckDownloadPdf, setListCheckDownloadPdf] = useState<number[]>(
    []
  );
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [listCheckException, setListCheckException] = useState<number[]>([]);
  const { getQueryString } = useQueryParams();

  const { filter, handleSearch, handleFilterChange, handlePageChange } =
    useFilter(
      {
        page: 1,
        limit: DEFAULT_LIMIT,
        startAt:
          queryParams?.startAt && dayjs(queryParams?.startAt).isValid()
            ? queryParams?.startAt
            : dayjs().startOf("month").toISOString(),
        endAt:
          queryParams?.endAt && dayjs(queryParams?.endAt).isValid()
            ? queryParams?.endAt
            : dayjs().endOf("month").toISOString(),
        keyword: (queryString.parse(getQueryString()) as any)?.keyword || "",
      },
      true
    );

  const { data: listInvoiceAgency, isLoading: loadingListListPost } = useQuery(
    [QueryKey.LIST_INVOICE_AGENCY, filter],
    () =>
      getListInvoiceAgency({
        ...filter,
      }),
    {
      onSuccess: (data) => {
        // console.log({ data });
      },
    }
  );

  // Hàm để back về tháng trước
  const handleBack = () => {
    handleFilterChange({
      startAt: dayjs(currentDate.subtract(1, "month"))
        .startOf("month")
        .toISOString(),
      endAt: dayjs(currentDate.subtract(1, "month"))
        .endOf("month")
        .toISOString(),
    });
    setCurrentDate(currentDate.subtract(1, "month"));
  };

  // Hàm để đi tới tháng tiếp theo
  const handleNext = () => {
    handleFilterChange({
      startAt: dayjs(currentDate.add(1, "month"))
        .startOf("month")
        .toISOString(),
      endAt: dayjs(currentDate.add(1, "month")).endOf("month").toISOString(),
    });
    setCurrentDate(currentDate.add(1, "month"));
  };

  const { mutate: downloadPdfInvoice, isLoading: loadingDownload } =
    useMutation(
      () =>
        getPdfInvoiceAgency(
          listCheckDownloadPdf?.length > 0
            ? listCheckDownloadPdf?.[0]
            : listCheckException?.[0],
          {
            startAt: currentDate.startOf("month").toISOString(),
            endAt: currentDate.endOf("month").toISOString(),
          }
        ),
      {
        onSuccess: (data) => {
          window.open(data?.data);
        },
        onError: (err: any) => {
          handleErrorMessage(err);
        },
      }
    );

  const { mutate: downloadPdfZipInvoice, isLoading: loadingDownloadZip } =
    useMutation(
      () =>
        getPdfZipInvoiceAgency({
          accountIds: isCheckAll
            ? undefined
            : listCheckDownloadPdf?.length > 0
            ? listCheckDownloadPdf
            : undefined,
          exceptionAccountIds: isCheckAll
            ? undefined
            : listCheckException?.length > 0
            ? listCheckException
            : undefined,
          startAt: currentDate.startOf("month").toISOString(),
          endAt: currentDate.endOf("month").toISOString(),
        }),
      {
        onSuccess: (data) => {
          window.open(data?.data);
        },
        onError: (err: any) => {
          handleErrorMessage(err);
        },
      }
    );

  return (
    <div className={styles.container}>
      {(loadingDownload || loadingDownloadZip) && <Loading />}

      <div className={styles.userHeader}>
        <div className={styles.topHeader}>
          <div className={styles.title}>{t("invoice.agency")}</div>
        </div>
        <InvoiceAgencyFilter
          filter={filter}
          handleSearch={handleSearch}
          // dataSelect={statusFilterUser}
          onSearch={handleFilterChange}
        />
      </div>
      <div className={styles.storeTable}>
        <div className={styles.wrapTable}>
          <div className={styles.wrapRowEnd}>
            <div className={styles.wrapRowFilter}>
              {dayjs().diff(dayjs(currentDate), "month") <= 5 && (
                <div onClick={handleBack} className={styles.wrapIconBack} />
              )}

              <p className={styles.titleAmount}>
                {currentDate.format("YYYY年M月")}
              </p>
              {!dayjs().isSame(dayjs(currentDate), "month") && (
                <div onClick={handleNext} className={styles.wrapIconNext} />
              )}
            </div>
          </div>
          <InvoiceAgencyTable
            data={listInvoiceAgency?.data?.items || []}
            filter={filter}
            loading={false}
            setListCheckDownloadPdf={setListCheckDownloadPdf}
            listCheckDownloadPdf={listCheckDownloadPdf}
            setIsCheckAll={setIsCheckAll}
            isCheckAll={isCheckAll}
            setListCheckException={setListCheckException}
            listCheckException={listCheckException}
          />
        </div>

        <PaginationCustom
          pageIndex={filter.page || 1}
          pageSize={filter.limit || 10}
          onPageChange={(page: number) => {
            handlePageChange(page, 100);
            // isCheckAll.check && setListCheckDownloadPdf([]);
            // setIsCheckAll({ check: false, page: page });
          }}
          totalItems={listInvoiceAgency?.data?.totalItem || 0}
          className="mt-20"
        />
        <div
          className={styles.wrapButtonDownLoad}
          onClick={() => {
            if (
              isCheckAll ||
              listCheckDownloadPdf?.length > 1 ||
              (listCheckException?.length > 1 &&
                listCheckException?.length <
                  listInvoiceAgency?.data?.totalItem - 1)
            ) {
              downloadPdfZipInvoice();
            }
            if (
              listCheckDownloadPdf?.length === 1 ||
              listCheckException?.length ===
                listInvoiceAgency?.data?.totalItem - 1
            )
              downloadPdfInvoice();
          }}
          style={{
            cursor:
              isCheckAll ||
              listCheckDownloadPdf?.length > 0 ||
              (listCheckException?.length <
                listInvoiceAgency?.data?.totalItem &&
                listCheckException?.length > 0)
                ? "pointer"
                : "not-allowed",
          }}
        >
          {t("invoice.downloadPdf")}
        </div>
      </div>
    </div>
  );
}
