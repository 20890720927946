import Icon from "@ant-design/icons";
import { Form, Input, Select } from "antd";
import { useTranslation } from "react-i18next";

import { ArrowBottom, SearchIconBlack } from "assets/icon";
import CustomButton from "components/CustomButton";
import styles from "./styles.module.scss";
import { IFilter } from "utils/interface";
import isEmpty from "lodash/isEmpty";
import { useEffect } from "react";

interface IProps {
  onSearch: any;
  dataSelect?: Array<any>;
  filter: IFilter;
}

export default function FAQFilter({
  dataSelect = [],
  onSearch,
  filter,
}: IProps) {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const { status, ...restFilter } = filter;

  useEffect(() => {
    if (!isEmpty(filter)) {
      form.setFieldsValue({
        ...restFilter,
      });
    }
  }, [filter]);

  return (
    <Form form={form} onFinish={onSearch}>
      <div className={styles.staffFilter}>
        <div className={styles.searchFilter}>
          <div className={styles.filterLabel}>{t("labelFilter.search")}</div>
          <div className={styles.inputSearch}>
            <Form.Item name="keyword" noStyle>
              <Input
                prefix={<Icon component={() => <SearchIconBlack />} />}
                className="inputCustom"
                placeholder={t("placeholder.search")}
              />
            </Form.Item>
          </div>
        </div>
        <div className={styles.statusFilter}>
          <div className={styles.filterLabel}>{t("labelFilter.status")}</div>
          <div className={styles.selectStatus}>
            <Form.Item name="status" noStyle>
              <Select
                className="selectCustom"
                suffixIcon={<Icon component={() => <ArrowBottom />} />}
              >
                {dataSelect?.map((item: any) => {
                  return (
                    <Select.Option key={item.status} value={item.status}>
                      {item.title}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
        </div>

        <CustomButton
          title={t("button.search")}
          className={styles.searchBtn}
          onClick={() => form.submit()}
        />
      </div>
    </Form>
  );
}
