import classNames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import { getListReport } from "api/report";
import PaginationCustom from "components/PaginationCustom";
import useFilter from "hooks/useFilter";
import useGetFilterQuery from "hooks/useGetFilterQuery";
import { DEFAULT_LIMIT } from "utils/const";
import { ContactSite, QueryKey } from "utils/enum";
import { convertFilterSite, handleErrorMessage } from "utils/helper";
import ReportTable from "./components/ReportTable";
import styles from "./styles.module.scss";

export default function ReportManagement() {
  const [t] = useTranslation();

  const { filter, handlePageChange, handleFilterChange, resetFilter }: any =
    useFilter(
      useGetFilterQuery({
        defaultFilter: {
          page: 1,
          limit: DEFAULT_LIMIT,
        },
        convertFilterToValidForm: convertFilterSite,
      }),
      true
    );

  const [site, setSite] = useState<ContactSite>(
    filter?.site || ContactSite.USER
  );

  const { data: listReportManage, isFetching: loadingListBug } = useQuery(
    [QueryKey.REPORT_LIST, filter, site],
    () =>
      getListReport({
        page: filter?.page,
        limit: filter?.limit,
      }),
    { onError: (error) => handleErrorMessage(error) }
  );

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={styles.postHeader}>
          <div className={styles.title}>{t("nav.report")}</div>
        </div>
      </div>
      <div className={styles.storeTable}>
        <div className={styles.tableContainer}>
          <ReportTable
            data={listReportManage?.data?.items || []}
            filter={filter}
            loading={loadingListBug}
          />
          <PaginationCustom
            pageIndex={filter.page || 1}
            pageSize={filter.limit || 10}
            onPageChange={handlePageChange}
            totalItems={listReportManage?.data?.totalItems || 0}
            className={classNames(styles.pagination)}
          />
        </div>
      </div>
    </div>
  );
}
