import { IBussinessGroup } from "utils/interface";
import { sendGet, sendPatch, sendPost } from "./axios";

export const getListBusinessGroup = (params: any) =>
  sendGet("/operation/business-group", params);

export const updateBusinessGroup = (data: IBussinessGroup) => {
  const { id, ...rest } = data;
  return sendPatch(`/operation/business-group/${id}`, rest);
};

export const createBusinessGroup = (data: any) =>
  sendPost("/operation/business-group", data);
