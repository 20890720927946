import { sendGet, sendPatch, sendPost } from "./axios";

interface IParam {
  page: number;
  limit: number;
  idUser?: number;
}

export const getUserList = (params: any) =>
  sendGet("/operation/account/candidate", params);

export const getDetailUserApi = (id?: string | number) =>
  sendGet(`/operation/account/candidate/${id}`);

export const getReviewUserApi = (id?: string | number, params?: any) =>
  sendGet(`/operation/account/candidate/${id}/review`, params);

export const getReviewCountUserApi = (id?: string | number) =>
  sendGet(`/operation/account/candidate/${id}/review/score`);

export const createUserApi = (data: any) =>
  sendPost(`/operation/account/candidate`, data);

export const getListExperience = () => sendGet("/candidate/experience");

export const getListPickupRequest = () => sendGet("/candidate/pickup-request");

export const getListPanel = () => sendGet("/candidate/panel");

export const getListCupSize = () => sendGet("/candidate/cup-size");

export const updateUserApi = (id: string | number, data: any) =>
  sendPatch(`/operation/account/candidate/${id}`, data);

export const getCountRecruitment = (idUser?: number) =>
  sendGet(`/operation/account/${idUser}/recruitment-post/task-bar/count`);

export const getListPostApplied = ({ page, limit, idUser }: IParam) =>
  sendGet(`/operation/account/${idUser}/recruitment-post/applied/list`, {
    page,
    limit,
  });
export const getListPostHired = ({ page, limit, idUser }: IParam) =>
  sendGet(`/operation/account/${idUser}/recruitment-post/hired/list`, {
    page,
    limit,
  });
export const getListPostDone = ({ page, limit, idUser }: IParam) =>
  sendGet(`/operation/account/${idUser}/recruitment-post/done/list`, {
    page,
    limit,
  });

export const getExp = (accountId: number | string) =>
  sendGet(`operation/ranking/histories/${accountId}`);
