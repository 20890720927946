import Icon from "@ant-design/icons";
import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";

import { SearchIconBlack } from "assets/icon";
import CustomButton from "components/CustomButton";
import isEmpty from "lodash/isEmpty";
import { useEffect } from "react";
import { IFilter } from "utils/interface";
import styles from "./styles.module.scss";

interface IProps {
  onSearch: any;
  filter: IFilter;
}

export default function GroupFilter({ onSearch, filter }: IProps) {
  const [t] = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    if (!isEmpty(filter)) {
      form.setFieldsValue(filter);
    }
  }, [filter]);

  return (
    <Form form={form} onFinish={onSearch}>
      <div className={styles.staffFilter}>
        <div className={styles.searchFilter}>
          <div className={styles.filterLabel}>{t("labelFilter.search")}</div>
          <div className={styles.inputSearch}>
            <Form.Item name="keyword" noStyle>
              <Input
                prefix={<Icon component={() => <SearchIconBlack />} />}
                className="inputCustom"
                placeholder={t("placeholder.search")}
              />
            </Form.Item>
          </div>
        </div>
        <CustomButton
          title={t("button.search")}
          className={styles.searchBtn}
          onClick={() => form.submit()}
        />
      </div>
    </Form>
  );
}
