import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import vi from "./locales/vi.json";
import en from "./locales/en.json";
import ja from "./locales/ja.json";
import configs from "constants/config";
import { LanguageType, ELocalStorageKey } from "utils/enum";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: LanguageType.JA,
    lng: configs.isDebugOrDev
      ? localStorage.getItem(ELocalStorageKey.I18) || LanguageType.JA
      : LanguageType.JA,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      vi: { translation: vi },
      en: { translation: en },
      ja: { translation: ja },
    },
  });
export default i18n;
