import { Checkbox, Table } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import type { ColumnsType } from "antd/es/table";
import { QueryKey } from "utils/enum";
import { IFilter, IRecordInvoice } from "utils/interface";
import {
  convertPoint,
  dateUtils,
  getIndexTable,
  handleErrorMessage,
  truncateText,
} from "utils/helper";
import { usePermissions, useRole } from "hooks/usePermissions";
import { dataRanking } from "constants/defaultValue";
import dayjs from "dayjs";
import styles from "./styles.module.scss";
import { invoiceStatusList } from "utils/const";
import { Detail } from "components/ActionTable/ActionTable";
import { useMutation, useQueryClient } from "react-query";
import { updateStatusInvoice } from "api/invoice";

interface IProps {
  data: IRecordInvoice[];
  filter: IFilter;
  loading: boolean;
}

export default function TableDetailInvoice({ data, filter, loading }: IProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns: ColumnsType<IRecordInvoice> = [
    {
      title: t("table.index"),
      dataIndex: "index",
      key: "index",

      render: (value, record, index) => (
        <div>{getIndexTable(filter.page || 1, filter.limit || 10, index)}</div>
      ),
    },
    {
      title: t("table.dateInvoice"),
      dataIndex: "dateInvoice",
      key: "dateInvoice",
      width: "18%",
      render: (value, record, index) => (
        <p>{dateUtils.getDateCreate(record?.createdAt)}</p>
      ),
    },
    {
      title: (
        <div className={styles.wrapItemTable}>
          <p>{t("table.postTitle")}</p>
        </div>
      ),
      dataIndex: "postTitle",
      key: "postTitle",
      render: (value, record, index) => {
        const truncatedText = truncateText({
          value: record?.recruitmentPost?.title,
          length: 8,
        });
        return (
          <div
            onClick={() => {
              navigate(`/posts/detail/${record?.recruitmentPost?.id}`);
            }}
            className={styles.wrapOnClick}
          >
            {truncatedText}
          </div>
        );
      },
    },
    {
      title: <p>{t("table.status")}</p>,
      dataIndex: "status",
      key: "status",
      render: (value, record, index) => (
        <div
          className={styles.wrapStatus}
          style={{
            borderWidth: `${
              invoiceStatusList[record?.historyStatus]?.border
            }px`,
            backgroundColor: invoiceStatusList[record?.historyStatus]?.color,
          }}
        >
          <p
            style={{
              color: invoiceStatusList[record?.historyStatus]?.textColor,
              fontSize: "16px",
            }}
          >
            {invoiceStatusList[record?.historyStatus]?.title}
          </p>
        </div>
      ),
    },
    {
      title: (
        <div className={styles.wrapItemTable}>
          <p>{t("table.dateWork")}</p>
        </div>
      ),
      dataIndex: "dateWork",
      key: "dateWork",
      render: (value, record, index) => (
        <>
          <p>{dateUtils.getDateCreate(record?.recruitmentPost?.startAt)}</p>
          <p>
            {dayjs(record?.recruitmentPost?.startAt)
              ?.add(record?.recruitmentPost?.businessHours, "hour")
              .format("YYYY/MM/DD HH:mm")}
          </p>
        </>
      ),
    },

    {
      title: <p className={styles.priceCol}>{t("table.price")}</p>,
      dataIndex: "amount",
      key: "amount",
      render: (value, record, index) => (
        <div className={styles.priceCol}>
          {!record?.amount ? "_" : convertPoint(record?.amount)}
        </div>
      ),
    },
  ];
  return (
    <>
      <Table
        rowKey={(obj) => obj?.id}
        pagination={false}
        columns={columns}
        dataSource={data}
        className="tableCustom"
        loading={loading}
        locale={{ emptyText: t("invoice.empty") }}
      />
    </>
  );
}
