import {
  convertFilterPageAndLimit,
  convertQueryDataObj,
  createQueryString,
} from "utils/helper";
import { IFilter, IFilterQuery } from "utils/interface";
import useQueryParams from "./useQueryParams";
import isEmpty from "lodash/isEmpty";

interface IProps {
  defaultFilter?: IFilter;
  convertFilterToValidForm?: any;
}

export default function useGetFilterQuery({
  defaultFilter,
  convertFilterToValidForm,
}: IProps) {
  const { getQueryString, setQueryObject } = useQueryParams();

  const filterDataObj = convertQueryDataObj(getQueryString());

  const formatFilter = !!convertFilterToValidForm
    ? convertFilterToValidForm({ ...defaultFilter, ...filterDataObj })
    : convertFilterPageAndLimit({
        ...defaultFilter,
        ...filterDataObj,
      } as IFilterQuery);

  const filterQueryParams = isEmpty(formatFilter)
    ? defaultFilter
    : formatFilter;

  if (isEmpty(filterDataObj) && defaultFilter) {
    setQueryObject(createQueryString(defaultFilter));
  }

  return filterQueryParams;
}
