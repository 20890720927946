import { CaretDownOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Row, Select } from "antd";
import i18next from "i18next";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import configs from "constants/config";
import { useAuth } from "hooks/useAuth";
import { useRole } from "hooks/usePermissions";
import { ELocalStorageKey, LanguageType, RoleType } from "utils/enum";
import styles from "./styles.module.scss";

interface IProps {
  isPageLogin?: boolean;
}

export default memo(function Header({ isPageLogin = false }: IProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const authContext = useAuth();
  const role = useRole();
  const isAdmin = role?.type === RoleType.ADMIN || !!role?.isSuperAdmin;
  const [currentLanguageCode, setCurrentLanguageCode] = useState(
    configs.isDebugOrDev
      ? localStorage.getItem(ELocalStorageKey.I18) || LanguageType.JA
      : LanguageType.JA
  );

  const listLanguages = [
    { code: "vi", name: "Vietnamese" },
    { code: "ja", name: "Japanese" },
  ];

  const handleChangeLanguage = (code: string) => {
    i18next.changeLanguage(code);
  };

  const renderSelectLanguage = () => {
    const listMenuLanguage = listLanguages.map(
      (item: { code: string; name: string }) => (
        <Menu.Item
          key={item.code}
          onClick={() => handleChangeLanguage(item.code)}
        >
          {item.name}
        </Menu.Item>
      )
    );
    return <Menu>{listMenuLanguage}</Menu>;
  };

  const menu = (
    <Menu>
      {!isAdmin && (
        <Menu.Item
          key="1"
          onClick={() => {
            navigate("/mypage");
          }}
        >
          {t("common.profile")}
        </Menu.Item>
      )}

      <Menu.Item
        key="2"
        onClick={() => authContext?.logout()}
        className={styles.logout}
      >
        {t("common.logout")}
      </Menu.Item>
    </Menu>
  );

  const handleSelectLanguageChange = (value: any) => {
    setCurrentLanguageCode(value);
    localStorage.setItem(ELocalStorageKey.I18, value);
    i18next.changeLanguage(value);
  };

  return (
    <div className={styles.header}>
      <div className={styles.contLeft}>
        {configs.isDebugOrDev && (
          <Select
            className={styles.languages}
            onChange={handleSelectLanguageChange}
            value={currentLanguageCode}
          >
            <Select.Option value={LanguageType.JA}>
              {t("languages.japanese")}
            </Select.Option>
            <Select.Option value={LanguageType.EN}>
              {t("languages.english")}
            </Select.Option>
            <Select.Option value={LanguageType.VI}>
              {t("languages.vietnamese")}
            </Select.Option>
            <Select.Option value={LanguageType.KEY}>
              {t("languages.showKey")}
            </Select.Option>
          </Select>
        )}
        {!isPageLogin && (
          <Dropdown overlay={menu} trigger={["click"]}>
            <Row align="middle">
              <span className={styles.email}>{`${
                localStorage.getItem(ELocalStorageKey.USERNAME)?.split("@")[0]
              }`}</span>
              &nbsp;
              <CaretDownOutlined />
            </Row>
          </Dropdown>
        )}
      </div>
    </div>
  );
});
