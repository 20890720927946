import configs from "constants/config";
import io from "socket.io-client";

const socket = io(configs.API_DOMAIN || "", {
  autoConnect: false,
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: Infinity,
});

export default socket;
