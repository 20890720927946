import Icon, {
  CopyOutlined,
  LockOutlined,
  StopOutlined,
  UnlockOutlined
} from "@ant-design/icons";

import { Button, Popconfirm } from "antd";
import {
  DeleteIcon2,
  DetailIcon,
  EditIcon2,
  IconTrashPdf,
  UnlockIcon
} from "assets/icon";
import styles from "./styles.module.scss";

interface IProps {
  onClick: (data: any) => any;
  textConfirm?: string;
}

interface IDeleteProps {
  onDelete: (data: any) => any;
  textConfirm?: string;
  placement?: "topRight" | "top";
}

function Unlock({ onClick }: IProps) {
  return (
    <Button
      type="text"
      className="p-2 flex justify-center align-center"
      onClick={(e: any) => {
        e.stopPropagation();
        onClick(e);
      }}
    >
      <Icon component={() => <UnlockIcon />} className={styles.icon} />
    </Button>
  );
}

function Edit({ onClick }: IProps) {
  return (
    <Button
      type="text"
      className="p-2 flex justify-center align-center"
      onClick={(e: any) => {
        e.stopPropagation();
        onClick(e);
      }}
    >
      <Icon component={() => <EditIcon2 />} className={styles.icon} />
    </Button>
  );
}

function Delete({ textConfirm, onDelete, placement }: IDeleteProps) {
  return (
    <Popconfirm
      title={textConfirm}
      placement={placement || "topLeft"}
      onConfirm={(e: any) => {
        e.stopPropagation();
        onDelete(e);
      }}
      onCancel={(e: any) => {
        e.stopPropagation();
      }}
      className={styles.popConfirm}
    >
      <Button
        type="text"
        className="p-2 flex justify-center align-center"
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        <Icon component={() => <DeleteIcon2 />} className={styles.icon} />
      </Button>
    </Popconfirm>
  );
}

function Detail({ onClick }: IProps) {
  return (
    <Button
      type="text"
      className="p-2 flex justify-center align-center"
      onClick={(e: any) => {
        e.stopPropagation();
        onClick(e);
      }}
    >
      <Icon component={() => <DetailIcon />} className={styles.icon} />
    </Button>
  );
}

function Disabled({
  textConfirm = "こちらを消除してもよろしいでしょうか？",
  onClick,
}: IProps) {
  return (
    <Popconfirm
      title={textConfirm}
      placement="topRight"
      onConfirm={(e: any) => {
        e.stopPropagation();
        onClick(e);
      }}
      onCancel={(e: any) => {
        e.stopPropagation();
      }}
    >
      <Button
        type="text"
        className="p-2 flex justify-center align-center"
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        <StopOutlined className={styles.icon__detail} />
      </Button>
    </Popconfirm>
  );
}

function Block({ textConfirm, onClick }: IProps) {
  return (
    <Popconfirm
      title={textConfirm}
      placement="topLeft"
      onConfirm={(e: any) => {
        e.stopPropagation();
        onClick(e);
      }}
      onCancel={(e: any) => {
        e.stopPropagation();
      }}
    >
      <Button
        type="text"
        className="p-2 flex justify-center align-center"
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        <UnlockOutlined className={styles.icon__detail_block} />
      </Button>
    </Popconfirm>
  );
}

function UnBlock({ textConfirm, onClick }: IProps) {
  return (
    <Popconfirm
      title={textConfirm}
      placement="topLeft"
      onConfirm={(e: any) => {
        e.stopPropagation();
        onClick(e);
      }}
      onCancel={(e: any) => {
        e.stopPropagation();
      }}
    >
      <Button
        type="text"
        className="p-2 flex justify-center align-center"
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        <LockOutlined className={styles.icon__detail_block} />
      </Button>
    </Popconfirm>
  );
}

function Copy({ onClick }: IProps) {
  return (
    <Button
      type="text"
      className="p-2 flex justify-center align-center"
      onClick={(e: any) => {
        e.stopPropagation();
        onClick(e);
      }}
    >
      <CopyOutlined className={styles.icon__detail} />
    </Button>
  );
}

function DeletePdf({ textConfirm, onDelete }: IDeleteProps) {
  return (
    <Popconfirm
      title={textConfirm}
      placement="topLeft"
      onConfirm={(e: any) => {
        e.stopPropagation();
        onDelete(e);
      }}
      onCancel={(e: any) => {
        e.stopPropagation();
      }}
      cancelText={"いいえ"}
      okText={"はい"}
    >
      <IconTrashPdf />
    </Popconfirm>
  );
}

export {
  Block, Copy, Delete, DeletePdf, Detail,
  Disabled, Edit, UnBlock, Unlock
};
