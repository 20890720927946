import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import classNames from "classnames";
import { Edit } from "components/ActionTable/ActionTable";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { getIndexTable } from "utils/helper";
import { IBussinessGroup, IFilter } from "utils/interface";
import styles from "./styles.module.scss";

interface IProps {
  data: IBussinessGroup[];
  filter: IFilter;
  onEdit: (data: any) => void;
}
export default function GroupTable({ data, filter, onEdit }: IProps) {
  const { t } = useTranslation();

  const columns: ColumnsType<any> = [
    {
      title: <div className={styles.leftMostCol}>{t("table.index")}</div>,
      dataIndex: "id",
      width: "20%",
      render: (value, record, index) => (
        <div className={styles.leftMostCol}>
          <span>
            {getIndexTable(filter.page || 1, filter.limit || 10, index)}
          </span>
        </div>
      ),
    },
    {
      title: t("table.groupname"),
      dataIndex: "groupName",
      width: "70%",
    },
    {
      title: t("table.action"),
      key: "action",
      render: (value, record, index) => (
        <div className="flex justify-center align-center">
          <Edit onClick={() => onEdit(record)} />
        </div>
      ),
      width: "10%",
    },
  ];

  return (
    <Fragment>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        className={classNames("tableCustom", styles.groupnameTbl)}
      />
    </Fragment>
  );
}
