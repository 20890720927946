import { sendGet, sendPost } from "./axios";

interface IParamInvoice {
  startAt: string;
  endAt: string;
  keyword: string;
}

interface IStatusInvoice {
  invoiceId: number;
  invoiceStatus?: boolean;
  paymentStatus?: boolean;
}

interface IStatusInvoiceAgent {
  agentInvoiceId: number;
  invoiceStatus?: boolean;
  paymentStatus?: boolean;
}
interface IPramsDownload {
  invoiceIds?: number[];
  exceptionInvoiceIds?: number[];
  startAt: string;
  endAt: string;
}

interface IPramsDownloadAgency {
  accountIds?: number[];
  exceptionAccountIds?: number[];
  startAt: string;
  endAt: string;
}

export const getListInvoice = (params: any) =>
  sendGet("/operation/invoice/list-invoices", params);

export const updateStatusInvoice = (params: IStatusInvoice) =>
  sendPost("/operation/invoice/update-status-invoice", params);

export const getListInvoiceDetail = (id: number, params: any) =>
  sendGet(`/operation/invoice/detail-invoices/${id}`, params);

export const getPdfInvoice = (id: number, params: IPramsDownload) =>
  sendGet(`/operation/invoice/export-invoices/export/${id}`, params);

export const getPdfZipInvoice = (params: IPramsDownload) =>
  sendGet(`/operation/invoice/export-invoices/export-zip`, params);

export const getListInvoiceAgency = (params: any) =>
  sendGet("/operation/invoice/list-agent-invoices", params);

export const updateStatusInvoiceAgency = (params: IStatusInvoiceAgent) =>
  sendPost("/operation/invoice/update-status-agent-invoice ", params);

export const getListInvoiceDetailAgency = (id: number, params: any) =>
  sendGet(`/operation/invoice/detail-agent-invoices/${id}`, params);

export const getPdfInvoiceAgency = (id: number, params: IPramsDownloadAgency) =>
  sendGet(`/operation/invoice/export-agent-invoices/export/${id}`, params);

export const getPdfZipInvoiceAgency = (params: IPramsDownloadAgency) =>
  sendGet(`/operation/invoice/export-agent-invoices/export-zip`, params);
