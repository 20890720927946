import { useRef, useState } from "react";

import { defaultFilterValue } from "constants/defaultValue";
import { applicationStatus, COMMON_STATUS, DateOption } from "utils/enum";
import { convertTimeToUTC, createQueryString } from "utils/helper";
import { IFilter } from "utils/interface";
import useQueryParams from "./useQueryParams";

export interface IFilterTypeSearch {
  keyword: any;
  startDate: any;
  endDate: any;
  status: any;
  plan: any;
  role: any;
}

export interface ISearchHelper {
  typeSearch: IFilterTypeSearch;
  handleSearch: any;
}

export default function useFilterAPI<T = IFilter>(
  defaultFilter?: T,
  isSaveInfoFilter?: boolean
) {
  const [filter, setFilter] = useState<T>(defaultFilter || ({} as T));
  const { setQueryObject } = useQueryParams();

  const filterRef: any = useRef();

  const setFilterRef = (data: { [key: string]: any }) => {
    filterRef.current = { ...filterRef.current, ...data };
  };

  const handleSearch = (changeValue: T) => {
    setFilter({
      ...filter,
      ...filterRef.current,
      page: 1,
    });
  };

  const custom = (name: string, data: any) => {
    setFilterRef({ [name]: data });
  };

  const keyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterRef({ keyword: e.target.value });
  };

  const startDate = (value: any) => {
    setFilterRef({
      startAt: convertTimeToUTC(value, DateOption.START_DATE),
    });
  };

  const endDate = (value: any) => {
    setFilterRef({
      endAt: convertTimeToUTC(value, DateOption.END_DATE),
    });
  };

  const status = (value: COMMON_STATUS | applicationStatus) => {
    setFilterRef({
      status: value !== COMMON_STATUS.ALL ? value : undefined,
    });
  };

  const role = (value: any) => {
    setFilterRef({
      roleId: value !== COMMON_STATUS.ALL ? value : undefined,
    });
  };

  const plan = (value: COMMON_STATUS) => {
    setFilterRef({
      plan: value !== COMMON_STATUS.ALL ? value : undefined,
    });
  };

  const handlePageChange = (page: number, pageSize: number) => {
    const newFilter = {
      ...filter,
      page,
      limit: pageSize,
    };
    if (isSaveInfoFilter) {
      setQueryObject(createQueryString(newFilter));
    }
    setFilter(newFilter);
  };

  const resetFilter = () => {
    if (defaultFilter) {
      setFilter({ ...defaultFilter });
    } else {
      setFilter({ ...defaultFilterValue } as T);
    }
  };

  return {
    filter,
    handleSearch,
    handlePageChange,
    resetFilter,
    typeSearch: {
      keyword,
      startDate,
      endDate,
      status,
      plan,
      role,
      custom,
    },
  };
}
