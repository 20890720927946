import { Button } from "antd";
import classNames from "classnames";
import styles from "./ImagesVideosList.module.scss";
import { PlayCircleOutlined } from "@ant-design/icons";
import {
  acceptedDocumentsType,
  acceptedImageType,
  acceptedVideoType,
  LIMIT_IMAGE_SIZE,
  LIMIT_VIDEO_SIZE,
} from "utils/const";
import i18next, { t } from "i18next";
import {
  IconDownload,
  IconLink,
  IconPdf,
  IconTrashPdf,
  IconTrashRed,
  PdfIcon,
} from "assets/icon";
import { checkTypeAllowed, convertNameFilePdf } from "utils/helper";
import { TIFFViewer } from "react-tiff";
import "react-tiff/dist/index.css";
import Link from "antd/es/typography/Link";
import { DeletePdf } from "components/ActionTable/ActionTable";
import axios from "axios";
import { Image } from "antd";

interface IDataItem {
  url: string;
  type: string;
  name: string;
  size?: number;
  path?: string;
}

interface Props {
  data: Array<IDataItem>;
  itemSizeWidth?: number | string;
  itemSizeHeight?: number | string;
  isWrapped?: boolean;
  isDeletable?: boolean;
  handleDelete?(indexToDelete: number): void;
  acceptedFilesTypeArr: Array<string>;
  isImageStoreDownloaded?: boolean;
}

export const ImagesVideosList = ({
  data,
  itemSizeWidth = 74,
  itemSizeHeight = 74,
  isWrapped = false,
  isDeletable = false,
  handleDelete,
  acceptedFilesTypeArr,
  isImageStoreDownloaded = false,
}: Props) => {
  const renderBtnClose = (dataIndex: number) => {
    return (
      <Button
        onClick={(e: any) => {
          e.stopPropagation();
          if (handleDelete) {
            handleDelete(dataIndex);
          }
        }}
        className={styles.imgContClose}
        size="small"
        shape="circle"
      >
        X
      </Button>
    );
  };

  const handleClickDownload = async (initUrl: string, name: string) => {
    axios
      .request({
        url: initUrl,
        method: "GET",
        responseType: "arraybuffer",
        headers: {
          "Cache-Control": "no-cache, no-store, must-revalidate",
          Pragma: "no-cache",
          Expires: "0",
        },
        // crossOrigin: true,
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log({ error }));
  };
  const checkSizeOk = ({
    size,
    limitSize,
  }: {
    size?: number;
    limitSize: number;
  }) => {
    return (
      size === undefined || (size !== undefined && Number(size) <= limitSize)
    );
  };
  return (
    <>
      <div
        className={classNames(styles.container, {
          [styles[`container--isWrapped`]]: !!isWrapped,
        })}
      >
        {data?.map((dataItem, dataIndex) => {
          // Images no need to check with **acceptedFilesTypeArr**
          if (
            checkTypeAllowed({
              curType: dataItem?.type,
              allowedTypes: ["image/tiff"],
            })
          ) {
            return (
              <div
                key={`${dataIndex}`}
                className={classNames([
                  checkSizeOk({
                    size: dataItem?.size,
                    limitSize: LIMIT_IMAGE_SIZE,
                  })
                    ? styles.imgWrapper__tiff
                    : styles.imgWrapper__tiffError,
                ])}
              >
                <TIFFViewer tiff={dataItem?.url} />
                {isDeletable && renderBtnClose(dataIndex)}
              </div>
            );
          }
          //images with store download
          if (
            checkTypeAllowed({
              curType: dataItem?.type,
              allowedTypes: acceptedImageType,
            }) &&
            isImageStoreDownloaded
          ) {
            return (
              <div
                key={`${dataIndex}`}
                style={{
                  width: "100%",
                }}
                className={styles.contImage}
              >
                <div
                  className={styles.imgWrapper}
                  style={{ height: itemSizeHeight, width: itemSizeWidth }}
                >
                  {dataItem?.url ? (
                    <Image
                      className={
                        checkSizeOk({
                          size: dataItem?.size,
                          limitSize: LIMIT_IMAGE_SIZE,
                        })
                          ? styles.imgCont
                          : styles.imgCont__error
                      }
                      style={{ height: itemSizeHeight, width: itemSizeWidth }}
                      alt=""
                      src={dataItem?.url}
                    />
                  ) : (
                    <div
                      className={styles.imgCont}
                      style={{ height: itemSizeHeight, width: itemSizeWidth }}
                    />
                  )}
                </div>
                <div className={styles.wrapListBottom}>
                  <IconDownload
                    style={{ marginRight: 20, cursor: "pointer" }}
                    onClick={() => {
                      handleClickDownload(
                        dataItem?.url,
                        convertNameFilePdf(dataItem?.name || "")
                      );
                    }}
                  />
                  <Link href={dataItem?.url} target="_blank">
                    <IconLink style={{ marginRight: 20 }} />
                  </Link>
                  {isDeletable && (
                    <DeletePdf
                      textConfirm={t("common.deletePdf")}
                      onDelete={() => {
                        handleDelete && handleDelete(dataIndex);
                      }}
                    />
                  )}
                </div>
              </div>
            );
          }
          // Images no need to check with **acceptedFilesTypeArr**
          if (
            checkTypeAllowed({
              curType: dataItem?.type,
              allowedTypes: acceptedImageType,
            })
          ) {
            return (
              <div
                key={`${dataIndex}`}
                className={styles.imgWrapper}
                style={{ height: itemSizeHeight, width: itemSizeWidth }}
              >
                {dataItem?.url ? (
                  <Image
                    className={
                      checkSizeOk({
                        size: dataItem?.size,
                        limitSize: LIMIT_IMAGE_SIZE,
                      })
                        ? styles.imgCont
                        : styles.imgCont__error
                    }
                    style={{ height: itemSizeHeight, width: itemSizeWidth }}
                    alt=""
                    src={dataItem?.url}
                  />
                ) : (
                  <div
                    className={styles.imgCont}
                    style={{ height: itemSizeHeight, width: itemSizeWidth }}
                  />
                )}
                {isDeletable && renderBtnClose(dataIndex)}
              </div>
            );
          }
          // Videos
          if (
            checkTypeAllowed({
              curType: dataItem?.type,
              allowedTypes: acceptedVideoType,
            })
          ) {
            return (
              <div
                key={`${dataIndex}`}
                className={styles.imgWrapper}
                style={{ height: itemSizeHeight, width: itemSizeWidth }}
              >
                <div
                  className={classNames(
                    checkSizeOk({
                      size: dataItem?.size,
                      limitSize: LIMIT_VIDEO_SIZE,
                    })
                      ? {}
                      : styles.imgCont__error,
                    "flex-column-center p-2"
                  )}
                  style={{
                    height: itemSizeHeight,
                    width: itemSizeWidth,
                    fontSize: 12,
                  }}
                >
                  <video
                    id={`video-${dataIndex}`}
                    className={
                      checkTypeAllowed({
                        curType: dataItem?.type,
                        allowedTypes: acceptedFilesTypeArr,
                      })
                        ? styles.imgCont
                        : styles.imgCont__error
                    }
                    style={{ height: itemSizeHeight, width: itemSizeWidth }}
                    controls
                  >
                    <source src={dataItem.url} />
                  </video>
                  <PlayCircleOutlined className={styles.imgPlayPause} />

                  {isDeletable && renderBtnClose(dataIndex)}
                </div>
              </div>
            );
          }
          // Documents
          if (
            checkTypeAllowed({
              curType: dataItem?.type,
              allowedTypes: acceptedDocumentsType,
            })
          ) {
            const pdfIconSize = (itemSizeHeight as any) / 4;
            return (
              <div key={`${dataIndex}`} style={{ width: "100%" }}>
                <div
                  className={
                    checkTypeAllowed({
                      curType: dataItem?.type,
                      allowedTypes: acceptedFilesTypeArr,
                    })
                      ? styles.contDocument
                      : styles.contDocument__error
                  }
                >
                  <IconPdf />
                  <div className={styles.txtDocument}>
                    {convertNameFilePdf(dataItem?.name || dataItem?.url)}
                  </div>
                  <div className={styles.wrapListBottom}>
                    <IconDownload
                      style={{ marginRight: 20, cursor: "pointer" }}
                      onClick={() => {
                        handleClickDownload(
                          dataItem?.url,
                          convertNameFilePdf(dataItem?.name || "")
                        );
                      }}
                    />
                    {/* </Link> */}
                    <Link href={dataItem?.url} target="_blank">
                      <IconLink style={{ marginRight: 20 }} />
                    </Link>
                    {isDeletable && (
                      <DeletePdf
                        textConfirm={t("common.deletePdf")}
                        onDelete={() => {
                          handleDelete && handleDelete(dataIndex);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            );
          }
          return (
            <div
              key={`${dataIndex}`}
              className={styles.imgWrapper}
              style={{ height: itemSizeHeight, width: itemSizeWidth }}
            >
              <div
                className={classNames(
                  styles.imgCont__error,
                  "flex-column-center p-2"
                )}
                style={{
                  height: itemSizeHeight,
                  width: itemSizeWidth,
                  fontSize: 12,
                }}
              >
                <div>
                  {i18next.t("errorText.wrongFormatFileDanger1") as any}
                </div>
                <div>
                  {i18next.t("errorText.wrongFormatFileDanger2") as any}
                </div>
              </div>
              {isDeletable && renderBtnClose(dataIndex)}
            </div>
          );
        })}
      </div>
    </>
  );
};
